var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Patrimônio Consolidado")])
          ])
        ],
        1
      ),
      _c(
        "v-form",
        [
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [
                  _vm._v(
                    "\n        Valor do Patrimônio Consolidado do Cliente\n      "
                  )
                ]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.lazy",
                        value: _vm.valorPatrimonio,
                        expression: "valorPatrimonio",
                        modifiers: { lazy: true }
                      },
                      {
                        name: "currency",
                        rawName: "v-currency",
                        value: _vm.currencyOptions,
                        expression: "currencyOptions"
                      }
                    ],
                    attrs: {
                      id: "valorPatrimonio",
                      maxlength: "14",
                      name: "valorPatrimonio",
                      size: "19",
                      type: "text"
                    },
                    domProps: { value: _vm.valorPatrimonio },
                    on: {
                      change: function($event) {
                        _vm.valorPatrimonio = $event.target.value
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }