<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Patrimônio Consolidado</div>
      </v-col>
    </v-row>
    <v-form>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Valor do Patrimônio Consolidado do Cliente
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input
            id="valorPatrimonio"
            maxlength="14"
            name="valorPatrimonio"
            size="19"
            type="text"
            v-model.lazy="valorPatrimonio"
            v-currency="currencyOptions"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import { mapClienteFields } from "../../../store/modules/caixaqui-clientes";
import { isValid, requiredMoney } from "../../../validators";

export default {
  name: "CaixaquiCadastroClientesPatrimonioConsolidado",

  data: () => ({
    style: {
      colLeft: 5,
      colRight: 7,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    validationMessages: {
      valorPatrimonio: { requiredMoney: "Preencha o valor do patrimônio consolidado do cliente " }
    }
  }),
  validations() {
    return {
      valorPatrimonio: { requiredMoney }
    };
  },
  computed: {
    ...mapClienteFields(["patrimonioConsolidado.valorPatrimonio"])
  },
  methods: {
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        if (isValid(this.$v, this.validationMessages)) {
          this.$router.push(to);
        }
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
#btn-salvar-alteracao,
#btn-cancelar-alteracao,
#btn-editar,
#btn-excluir,
#btn-salvar {
  padding: 3px 12px 3px 12px;
}
</style>
